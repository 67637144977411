require('../img/logo_Turfoo.gif');
require('../img/LogoTurfoo.png');
require('../img/+18.png');
require('../img/BANNIERE_120x600.gif');
require('../img/svg/turfoo-horse.svg');
require('../img/calendar.png');

import flatpickr from "flatpickr";
import {French} from "flatpickr/dist/l10n/fr.js";
import '../css/app.css'
import '../css/base.css'
import '../css/footer.css'

document.addEventListener('DOMContentLoaded', function () {
    const rss = document.getElementById('rss');
    const navButton = document.querySelector('button[aria-expanded]');
    const overlay = document.querySelector('.overlay');
    const menuMobile = document.querySelector('.menu-mobile');
    const closeButton = document.querySelector('.close-button');


    var fleche1 = document.querySelector('.has-sublevel1');
    var fleche2 = document.querySelector('.has-sublevel2');
    var fleche3 = document.querySelector('.has-sublevel3');
    var fleche4 = document.querySelector('.has-sublevel4');
    var fleche5 = document.querySelector('.has-sublevel5');


    var retour1 = document.querySelector('.comeBack2_1');
    var retour2 = document.querySelector('.comeBack2_2');
    var retour3 = document.querySelector('.comeBack2_3');
    var retour4 = document.querySelector('.comeBack2_4');
    var retour5 = document.querySelector('.comeBack2_5');

    var level1 = document.querySelector('.level1');
    var level2_1 = document.querySelector('.level2_1');
    var level2_2 = document.querySelector('.level2_2');
    var level2_3 = document.querySelector('.level2_3');
    var level2_4 = document.querySelector('.level2_4');
    var level2_5 = document.querySelector('.level2_5');


    function toggleNav() {
        const expanded = this.getAttribute('aria-expanded') === 'true' || false;
        navButton.setAttribute('aria-expanded', !expanded);
        menuMobile.classList.toggle('invisible');
        menuMobile.classList.toggle('opacity-0');
        overlay.classList.toggle('invisible');
        overlay.classList.toggle('opacity-0');
    }

    navButton.addEventListener('click', toggleNav);

    if (closeButton) {
        closeButton.addEventListener('click', function () {
            menuMobile.classList.toggle('invisible');
            menuMobile.classList.toggle('opacity-0');
            navButton.setAttribute('aria-expanded', false);
            overlay.classList.toggle('invisible');
            overlay.classList.toggle('opacity-0');
        })
    }

    if (overlay) {
        overlay.addEventListener('click', function () {
            menuMobile.classList.toggle('invisible');
            menuMobile.classList.toggle('opacity-0');
            navButton.setAttribute('aria-expanded', false);
            overlay.classList.toggle('invisible');
            overlay.classList.toggle('opacity-0');
        })
    }

    if (fleche1) {
        fleche1.addEventListener('click', function () {
            level1.classList.add('hidden');
            level2_1.classList.toggle('hidden');
        })
    }

    if (retour1) {
        retour1.addEventListener('click', function () {
            level1.classList.toggle('hidden');
            level2_1.classList.add('hidden');
        })
    }
    if (fleche2) {
        fleche2.addEventListener('click', function () {
            level1.classList.add('hidden');
            level2_2.classList.toggle('hidden');
        })
    }

    retour2.addEventListener('click', function () {
        level1.classList.toggle('hidden');
        level2_2.classList.add('hidden');
    })

    fleche3.addEventListener('click', function () {
        level1.classList.add('hidden');
        level2_3.classList.toggle('hidden');
    })

    retour3.addEventListener('click', function () {
        level1.classList.toggle('hidden');
        level2_3.classList.add('hidden');
    })

    fleche4.addEventListener('click', function () {
        level1.classList.add('hidden');
        level2_4.classList.toggle('hidden');
    })

    retour4.addEventListener('click', function () {
        level1.classList.toggle('hidden');
        level2_4.classList.add('hidden');
    })

    fleche5.addEventListener('click', function () {
        level1.classList.add('hidden');
        level2_5.classList.toggle('hidden');
    })

    retour5.addEventListener('click', function () {
        level1.classList.toggle('hidden');
        level2_5.classList.add('hidden');
    })

    var today = new Date();
    var expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000); // plus 30 days

    function setCookie(name, value) {
        document.cookie = name + "=" + escape(value) + "; path=/; expires=" + expiry.toGMTString();
    }

    var expired = new Date(today.getTime() - 24 * 3600 * 1000); // less 24 hours

    function deleteCookie(name) {
        document.cookie = name + "=null; path=/; expires=" + expired.toGMTString();
    }

    function getCookie(name) {
        var re = new RegExp(name + "=([^;]+)");
        var value = re.exec(document.cookie);
        return (value != null) ? unescape(value[1]) : null;
    }

    // var head = document.head || document.getElementsByTagName('head')[0];
    // var style = document.createElement('style');
    // style.type = 'text/css';
    // var css = 'body,img,video{-webkit-filter:invert(1)hue-rotate(180deg)contrast(70%) brightness(80%);filter:invert(1)hue-rotate(180deg)contrast(70%) brightness(80%)}';
    // if (style.styleSheet) {
    //     style.styleSheet.cssText = css;
    // } else {
    //     style.appendChild(document.createTextNode(css));
    // }

    if (rss !== null) {
        rss.addEventListener('click', function () {
            var detailRss = document.getElementsByClassName('main__footer__news__options');
            if (detailRss !== null) {
                detailRss[0].classList.toggle('hidden');
            }
        });
    }
    ;

    var basesixty = document.querySelectorAll('.basesixty');
    basesixty.forEach(function (item) {
        item.addEventListener('click', function (e) {
            e.preventDefault()
            window.open(atob(item.attributes['data-href'].nodeValue), '_self')
        })
    })

    var tableHorseAvis = document.querySelectorAll('.more-info--horse');
    tableHorseAvis.forEach(function (item) {
        item.addEventListener('click', function (e) {
            item.parentNode.classList.toggle("not-extended");
        })
    })

    var music = document.querySelectorAll('.table-horses:not(.zeturf) .music');
    music.forEach(function (item) {
        item.innerText = item.innerText.replace(/\s+/g, '');
        item.innerText = item.innerText.slice(0, 16);
    });

    var musicZeturf = document.querySelectorAll('.table-horses.zeturf .music');
    musicZeturf.forEach(function (item) {
        item.innerText = item.innerText.replace(/\s+/g, '');
        item.innerText = item.innerText.slice(0, 20);
    });

    if (document.querySelectorAll('.avis')[0]) {
        var allAvis = document.querySelectorAll('.avis')[0];
        allAvis.addEventListener('click', function (e) {
            var tableHorseAvis = document.querySelectorAll('.more-info--horse');
            var isExtended = tableHorseAvis[0].parentNode.classList.contains("not-extended");

            tableHorseAvis.forEach(function (item) {

                if (isExtended) {
                    item.parentNode.classList.remove("not-extended");
                } else {
                    item.parentNode.classList.add("not-extended");
                }

            })
        })
    } else {
        console.log('Avis is null')
    }


});

